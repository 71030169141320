











































































































import { Component, Vue, Watch } from "vue-property-decorator";
import CityPane from "@/components/movie/city-pane.vue";
import NationPane from "@/components/movie/nation-pane.vue";
import platform_category_list from "./platform_category_list.vue";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import { cityList } from "@/api/movie";
import { CinemaCityStatus } from "@/utils/type";
@Component({
    components: {
        CityPane,
        NationPane,
        ExportData,
        platform_category_list,
    },
})
export default class Goods extends Vue {
    queryObj: any = {
        name: "",
    };
    tableint: any = "";
    supplierList: any[] = [];
    areaLists: any[] = [];
    index_type: any;

    tabCount = {
        priced: 0,
        unpriced: 0,
    };
    activeType = "1";
    pager = new RequestPaging({ page: 1, size: 50 });
    activeName: any = "priced";
    cinemaActiveName: any = "1";
    apiGoodsLists = cityList;

    getList(page?: number, type?: number): void {
        page && (this.pager.page = page);
        this.tableint = CinemaCityStatus[this.activeName];
        this.pager
            .request({
                callback: cityList,
                params: {
                    status: CinemaCityStatus[this.activeName],
                    ...this.queryObj,
                },
            })
            .then((res: any) => {
                this.tabCount = res?.extend;
            });
    }

    handleReset() {
        this.queryObj = {
            name: "",
        };
        this.getList();
    }

    created() {
        this.index_type = this.$route.query;
        if (this.index_type?.index_type) {
            this.cinemaActiveName = this.index_type.index_type;
        }
        this.getList();
        // this.getGoodsOtherList()
    }
}
