import { render, staticRenderFns } from "./set-category-price.vue?vue&type=template&id=59f79e8c&scoped=true&"
import script from "./set-category-price.vue?vue&type=script&lang=ts&"
export * from "./set-category-price.vue?vue&type=script&lang=ts&"
import style0 from "./set-category-price.vue?vue&type=style&index=0&id=59f79e8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f79e8c",
  null
  
)

export default component.exports