






















































































































































import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import setCategoryPrice from "@/components/set-category-price.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
import {
  apiPlatformCategoryList,
  apiSetCategoryPrice,
  apiCategoryPickOut,
} from "@/api/goods";
import { apiShopLists } from "@/api/shop";
import transfer from "@/components/transfer.vue";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    setCategoryPrice,
    transfer,
  },
})
export default class orderEdit extends Vue {
  apiPlatformCategoryList = apiPlatformCategoryList;
  @Prop() semiSeq!: string;
  $refs!: { priceForm: any; pickForm: any };

  pager: RequestPaging = new RequestPaging();
  searchObj: any = {
    name: "",
  };
  dialogCategory: boolean = false;
  dialogPrice: boolean = false;
  price_loading: boolean = false;
  is_pick: boolean = false;
  shop_list: any = [];
  dialog_title: string = "";
  multipleSelection: any[] = [];

  // 挑选
  pickForm: any = {
    category_id: [],
    shop_data: [],
  };
  // 定价
  priceForm: any = {
    shop_price_type: "",
    price_method: "",
    value: {
      price_list: {
        price_type: "1",
        price_ratio: "1",
        price_add_sub: "1",
        price_number: "1",
      },
      price_rounding: "1",
      reserve_decimal: 2,
    },
  };

  // 列表
  getCategoryList(page?: number) {
    page && (this.pager.page = page);
    this.pager.request({
      callback: apiPlatformCategoryList,
      params: {
        ...this.searchObj,
      },
    });
  }
  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getCategoryList(1);
  }

  //表格全选
  handleSelectionChange(value: any) {
    this.multipleSelection = value;
  }

  // 批量挑选
  pickAllCategory() {
    this.dialog_title = `挑选加入商城（已选中${this.multipleSelection.length}个类目）`;
    this.is_pick = true;
    this.dialogCategory = true;
    const ids = this.multipleSelection.map((v) => v.id);
    this.pickForm = {
      category_id: ids,
      shop_data: [],
    };
  }
  // 挑选
  pickCategory(item: any) {
    this.dialog_title = "挑选加入商城（已选中1个类目）";
    this.is_pick = true;
    this.dialogCategory = true;
    this.pickForm = {
      category_id: [item.id],
      shop_data: [],
    };
  }
  handleConfirmPick() {
    if (this.pickForm.shop_data.length === 0) {
      this.$message.warning("请选择商城");
      return;
    }
    this.dialog_title = "批量定价";
    this.dialogCategory = false;
    this.dialogPrice = true;
    this.priceForm = {
      price_method: "1",
      value: {
        price_list: {
          price_type: "1",
          price_ratio: "1",
          price_add_sub: "1",
          price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: "2",
      },
    };
  }
  resetShop() {
    this.dialogPrice = false;
    this.dialogCategory = true;
  }

  // 批量改价
  changeAllPrice() {
    this.dialog_title = "批量定价";
    this.dialogPrice = true;
    this.is_pick = false;
    const ids = this.multipleSelection.map((v) => v.id);
    this.priceForm = {
      category_id: ids,
      price_method: "",
      value: {
        price_list: {
          price_type: "1",
          price_ratio: "1",
          price_add_sub: "1",
          price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: 2,
      },
    };
  }
  changePrice(item: any) {
    this.dialog_title = "定价";
    this.dialogPrice = true;
    this.is_pick = false;
    if (item.price_method && item.value) {
      this.priceForm = {
        category_id: [item.id],
        price_method: item["price_method"],
        // value: item["value"],
        value: {
          price_list: {
            price_type: item["value"].price_list.price_type || "1",
            price_ratio: item["value"].price_list.price_ratio || "1",
            price_add_sub: item["value"].price_list.price_add_sub || "1",
            price_number: item["value"].price_list.price_number || "1",
          },
          price_rounding: item["value"].price_rounding || "1",
          reserve_decimal: item["value"].reserve_decimal || 2,
        },
      };
      return;
    }
    this.priceForm = {
      category_id: [item.id],
      price_method: "1",
      value: {
        price_list: {
          price_type: "1",
          price_ratio: "1",
          price_add_sub: "1",
          price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: 2,
      },
    };
  }

  async handleConfirm() {
    const valid = await this.$refs.priceForm.validate();
    if (!valid) {
      return;
    }
    this.price_loading = true;
    let data = {};
    data = this.is_pick
      ? { ...this.priceForm, ...this.pickForm }
      : this.priceForm;
    try {
      const res = this.is_pick
        ? await apiCategoryPickOut(data)
        : await apiSetCategoryPrice(data);
      this.$message({
        type: "success",
        message: this.is_pick ? "挑选成功" : "改价成功",
      });
      this.price_loading = false;
      this.dialogPrice = false;
      this.getCategoryList();
    } catch (error) {
      this.price_loading = false;
    }
  }

  // 商城
  async getShop() {
    this.pager.loading = true;
    const res = await apiShopLists({
      page_type: 0,
      app_id: 10,
    });
    this.pager.loading = false;
    this.shop_list = res.lists;
  }
  async created() {
    await this.getShop();
    this.getCategoryList();
  }
}
